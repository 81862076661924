exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-connect-jsx": () => import("./../../../src/pages/connect.jsx" /* webpackChunkName: "component---src-pages-connect-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-nondiscrimination-notice-jsx": () => import("./../../../src/pages/legal/nondiscrimination-notice.jsx" /* webpackChunkName: "component---src-pages-legal-nondiscrimination-notice-jsx" */),
  "component---src-pages-legal-privacy-policy-jsx": () => import("./../../../src/pages/legal/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-jsx" */),
  "component---src-pages-legal-terms-and-conditions-jsx": () => import("./../../../src/pages/legal/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-legal-terms-and-conditions-jsx" */),
  "component---src-pages-more-resources-jsx": () => import("./../../../src/pages/more-resources.jsx" /* webpackChunkName: "component---src-pages-more-resources-jsx" */)
}

